import '../Home/landing.scss';
import CoreValues from "../CoreValues";
import InsurancePanel from "../InsurancePanel/InsurancePanel";
import Introduction from '../Introduction';
import ServiceSummary from '../ServiceSummary';
import WhatWeDo2 from '../WhatWeDo2';
import Project from '../Project';

export default function Landing() {
    return (
        <div>
            <Introduction />
            <ServiceSummary />
            <Project />
            <CoreValues />
            <WhatWeDo2 />
            <InsurancePanel />
        </div>
        
        
    );
}