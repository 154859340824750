import "./gallery.scss";
import 'react-slideshow-image/dist/styles.css';
import gallery1 from '../../assets/gallery/gallery1.jpeg';
import gallery2 from '../../assets/gallery/gallery2.jpeg';
import gallery3 from '../../assets/gallery/gallery3.jpeg';
import gallery4 from '../../assets/gallery/gallery4.jpeg';
import gallery5 from '../../assets/gallery/gallery5.jpeg';
import gallery6 from '../../assets/gallery/gallery6.jpeg';
import gallery7 from '../../assets/gallery/gallery7.jpeg';
import gallery8 from '../../assets/gallery/gallery8.jpeg';
import gallery9 from '../../assets/gallery/gallery9.jpeg';
import gallery10 from '../../assets/gallery/gallery10.jpeg';
import gallery11 from '../../assets/gallery/gallery11.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';


export default function Gallery() {
    return (
        <div style={{marginTop: 30, paddingLeft: 25}}>
            <Swiper
                breakpoints={{
                300: {
  
                  slidesPerView: 1,
                },
                768:{
                    slidesPerView:4
                }
              }}
                slidesPerView={4}
                spaceBetween={2}
                pagination={{
                clickable: true,
                }}
                modules={[Pagination]}
                className="swiper"
            >
                <SwiperSlide><img src={gallery1} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery2} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery3} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery4} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery5} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery6} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery7} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery8} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery9} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery10} alt=""></img></SwiperSlide>
                <SwiperSlide><img src={gallery11} alt=""></img></SwiperSlide>
            </Swiper>
        </div>
    )
}