import Image from '../assets/10.jpeg'

export default function CoreValues() {
    return (
        <div className='core-value-background'>
            <div className='container content-container'>
                <div className='row landing-wrapper'>
                    <div className='col-lg-6'>
                        <img src={Image} alt="" width="80%" className='core-value-image'></img>
                    </div>
                    <div className='col-lg-6 separator'>
                        <p style={{fontSize: 14, color: "#3f51b5"}}><i>OUR CORE VALUES</i></p>
                        <h2 style={{color:"#3f51b5"}}><b>We are largest independent sit manufacturing</b></h2>
                        <div className="row" style={{display:"flex", justifyContent:"space-around"}}>
                            <div className='col-lg-6 landing-wrapper'>
                                <div className='small-container-purple'>
                                    <h5 style={{padding: "10px 20px 0px 20px"}}><span className='bi bi-clipboard-check-fill' style={{color: "white", paddingRight: 10}}></span><b>Quality</b></h5>
                                    <p style={{padding:"10px 20px 0px 20px"}}>Striving for excellence in repairs and services to ensure customer satisfaction and vehicle safety.</p>
                                </div>
                            </div>
                            <div className='col-lg-6 landing-wrapper'>
                                <div className='small-container-yellow'>
                                    <h5 style={{padding: "10px 20px 0px 20px"}}><span className='bi bi-search-heart' style={{color: "white", paddingRight: 10}}></span><b>Integrity</b></h5>
                                    <p style={{padding: "10px 20px 0px 20px"}}>Conducting business with honesty, transparency, and ethical practices to build trust with customers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{display:"flex", justifyContent:"space-around"}}>
                            <div className='col-lg-6 landing-wrapper'>
                                <div className='small-container-yellow'>
                                    <h5 style={{padding: "10px 20px 24px 20px"}}><span className='bi bi-person-bounding-box' style={{color: "white", paddingRight: 10}}></span><b>Customer Focus</b></h5>
                                    <p style={{padding: "10px 20px 0px 20px"}}>Placing the needs of the customer at the forefront and ensuring their satisfaction through attentive service.</p>
                                </div>
                            </div>
                            <div className='col-lg-6 landing-wrapper'>
                                <div className='small-container-purple'>
                                    <h5 style={{padding: "10px 20px 0px 20px"}}><span className='bi bi-building-gear' style={{color: "white", paddingRight: 10}}></span><b>Continuous Improvement</b></h5>
                                    <p style={{padding: "10px 20px 0px 20px"}}>Commitment to ongoing learning, staying updated with industry advancements, and improving service delivery for better customer experiences.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    )
}