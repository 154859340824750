import "../ContactUs/contact_us.scss";

export default function ContactUs() {
    return (
        <div id="contact" className='contact-container contact-background contact-mobile'>
            <div className='col-lg-3'>
                <h2 style={{color: "#3f51b5"}}><b>Contact Us</b></h2>
                <div style={{fontSize: 18, paddingBottom:30}}>
                    <div className="bi bi-geo-alt-fill" style={{color: "#3f51b5"}}>Location</div>
                    <div className="info-container">No. 54 & 55, Jln Industri 3, Taman Perindustrian Lukut Indah 71010 Port Dickson, Negeri Sembilan.</div>
                </div>
                <div style={{fontSize: 18, paddingBottom:30}}>
                    <div className="bi bi-telephone-fill" style={{color: "#3f51b5"}}>Phone Number</div>
                    <div className="info-container">06-6511689</div>
                    <div className="info-container">06-6511841</div>
                </div>
                <div style={{fontSize: 18, paddingBottom:30}}>
                    <div className="bi bi-whatsapp" style={{color: "#3f51b5"}}>WhatsApp</div>
                    <div className="info-container">012-6514861</div>
                </div>
                <div style={{fontSize: 18, paddingBottom:30}}>
                    <div className="bi bi-envelope-fill" style={{color: "#3f51b5"}}>Email</div>
                    <div className="info-container">thianwah1689@gmail.com</div>
                </div>
            </div>
            <div className='col-lg-7'>
            <iframe title="Thian Wah Motor" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3985.7948788038207!2d101.82816887437961!3d2.573335556423571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdee3aced20075%3A0xca68606589e5872f!2sThian%20Wah%20Motor%20Sdn.bhd!5e0!3m2!1sen!2smy!4v1699971524105!5m2!1sen!2smy" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}