import './app.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core/dist/umd/popper.min.js";
import Router from './page/Router';
import Navbar from './component/Navbar/Navbar';
import Footer from './component/Footer/Footer';

function App() {
	return (
		<div className='app-content'>
			<Navbar />
			<Router />
			<Footer />
		</div>
	);
}

export default App;
