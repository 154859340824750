import '../InsurancePanel/insurance_panel.scss';
import Insurance from "../../assets/Insurance.png";

export default function InsurancePanel() {
   return (
    <div>
        <div className='services-content' style={{marginTop: 50}}>
            <h2 style={{color: "#3f51b5", marginBottom: 20}}><b>Insurance Panel</b></h2>
        </div>
        <div className='insurance-container'>
            <p style={{fontSize:18, textAlign: 'center'}}>Thian Wah Motor is the Full Panel Workshop for most of all the Insurance companies in Malaysia.</p>
            <p style={{fontSize:18, textAlign: 'center', marginBottom: 20}}>If you need any assistance on making a claim, we are always here to help for guidelines on Insurance Claims, Repair & Disputes. </p>
            <div className="image-panel">
                <img src={Insurance} alt='' style={{width: "100%"}}></img>
            </div>
            
        </div>
    </div>
   )
}