import { Link } from "react-router-dom"
import Carousel from "../Carousel/Carousel"
import '../Navbar/navbar.scss'
import logo from '../../assets/logo.png'

export default function Navbar() {
    return (
        // <div>
        //     <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
        //         <div className="row" style={{width: "100%", position: "relative", left: 12}}>
        //             <div className="col-lg-4" style={{backgroundColor: "#3f51b5", paddingTop: 8, paddingBottom: 8, color: "white"}}>
        //                 # Leaders in Heavy Vehicle Insurance Claims and Repairs.
        //             </div>
        //             <div className="col-lg-8" style={{textAlign: "end", backgroundColor: "#92801d", paddingTop: 5, paddingBottom: 5, color: "white"}}>
        //                 <div className="row">
        //                 <div className="col-lg-9" style={{paddingRight: 30}}>
        //                     Monday - Saturday 9:00 AM - 5:30 PM 
        //                 </div>
        //                 <div className="col-lg-3">
        //                     Sunday/Public Holiday Closed
        //                 </div>
        //                 </div>
                           
        //             </div>
        //             <div className="container-fluid nav-container" style={{display: "flex"}}>
        //                 <div style={{display: "flex", alignSelf: "center", paddingTop: 10, marginBottom: 10, justifyContent:'space-between'}}>
        //                     <div>
        //                         <Link className="navbar-brand" to="/">
        //                             <img src={logo} alt="Thian Wah Motor" width="60px" style={{marginLeft: 10}}/>
        //                         </Link>
        //                         <div style={{color:"#3f51b5", alignSelf: "center"}}>Thian Wah Motor Sdn. Bhd.</div>
        //                     </div>
        //                     <div>
        //                         <div style={{alignSelf: "center"}}>
        //                             <div>
        //                                 <ul className="navbar-nav mb-2 mb-lg-0 ms-auto me-3">
        //                                     <li className="nav-item">
        //                                         <a className="nav-link" href="/">Home</a>
        //                                     </li>
        //                                     <li className="nav-item">
        //                                         <a className="nav-link" aria-current="page" href="/about/#about">About Us</a>
        //                                     </li>
        //                                     <li className="nav-item">
        //                                         <a className="nav-link" href="/services/#services">Services</a>
        //                                     </li>
        //                                     <li className="nav-item">
        //                                         <a className="nav-link" href="/contact/#contact">Contact</a>
        //                                     </li>
        //                                 </ul>
        //                             </div>
        //                         </div>
        //                     </div>
                            
        //                 </div>
        //             </div>
        //         </div>
        //     </nav>
        //     <Carousel />
        // </div>

        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
            <div className="row" style={{width: "100%", position: "relative", left: 12}}>
                <div className="col-lg-4" style={{backgroundColor: "#3f51b5", paddingTop: 8, paddingBottom: 8, color: "white"}}>
                    #1 Leader in Heavy Vehicle Insurance Claims and Repairs.
                </div>
                <div className="col-lg-8" style={{textAlign: "end", backgroundColor: "#92801d", paddingTop: 5, paddingBottom: 5, color: "white"}}>
                    <div className="row">
                        <div className="col-lg-8" style={{paddingRight: 30}}>
                            Monday - Saturday 9:00 AM - 5:30 PM 
                        </div>
                        <div className="col-lg-4">
                            Sunday/Public Holiday Closed
                        </div>
                    </div>
                </div>
                <div className="container-fluid nav-container" style={{paddingTop: 10, paddingBottom: 10}}>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{alignSelf: "center", marginBottom: 10}}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} alt="Thian Wah Motor" width="60px" />
                        </Link>
                        <Link to="/" style={{color:"#3f51b5", alignSelf: "center"}} className="nav-decoration">Thian Wah Motor Sdn. Bhd.</Link>
                        <ul className="navbar-nav mb-2 mb-lg-0 ms-auto me-3">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/about/#aboutus">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/services/#services">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/contact/#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>   
            </nav>
            <Carousel />
        </div>
    )
}