import Image from '../assets/serviceSummary.jpeg'

export default function ServiceSummary() {
    return (
        <div className='content-container top-yellow-background'>
            <div style={{display: 'flex', justifyContent:'space-between'}} className='col-lg-6'>
                <div className='our-service-title'>
                    <b>Our Services</b>
                </div>
                <div style={{alignSelf: 'flex-end', color: "#3f51b5", cursor: "pointer"}}><a href='/services/#services ' style={{textDecoration: "none"}} ><i style={{color: "#3f51b5"}}>See more</i><span className='bi bi-arrow-right-short' style={{color: "#3f51b5"}}></span></a></div>
            </div>
            <div className='hl-service-summary'></div>
            
            <div className='col-lg-5 small-view-service-summary'>
                <div style={{marginBottom:30}}>
                    <div className='category-small-container-purple'>
                        <h5 style={{padding: "10px 20px 0px 20px", fontSize: 20}}><b>Lorry</b></h5>
                        <li style={{padding:"10px 20px 0px 20px"}}>Trailer Repair Service</li>
                        <li style={{padding:"10px 20px 0px 20px"}}>JOSAM Truck Frame Repair</li>
                        <li style={{padding:"10px 20px 0px 20px"}}>JOSAM Induction Heater</li>
                    </div>
                </div>

                <div>
                    <div className='category-small-container-yellow'>
                        <h5 style={{padding: "10px 20px 0px 20px"}}><b>Car</b></h5>
                        <li style={{padding:"10px 20px 0px 20px"}}>Accident Repairing</li>
                        <li style={{padding:"10px 20px 0px 20px"}}>Windscreen Replacement</li>
                        <li style={{padding:"10px 20px 0px 20px"}}>Stamping Engine number & Chasis number</li>
                    </div>
                </div>
                
            </div>

            <div className='category-info-container'>
                <div className='col-lg-5'>
                    <div style={{marginBottom:30}}>
                        <div className='category-small-container-purple'>
                            <h5 style={{padding: "10px 20px 0px 20px", fontSize: 20}}><b>Lorry</b></h5>
                            <li style={{padding:"10px 20px 0px 20px"}}>Trailer Repair Service</li>
                            <li style={{padding:"10px 20px 0px 20px"}}>JOSAM Truck Frame Repair</li>
                            <li style={{padding:"10px 20px 0px 20px"}}>JOSAM Induction Heater</li>
                        </div>
                    </div>

                    <div>
                        <div className='category-small-container-yellow'>
                            <h5 style={{padding: "10px 20px 0px 20px"}}><b>Car</b></h5>
                            <li style={{padding:"10px 20px 0px 20px"}}>Accident Repairing</li>
                            <li style={{padding:"10px 20px 0px 20px"}}>Windscreen Replacement</li>
                            <li style={{padding:"10px 20px 0px 20px"}}>Stamping Engine number & Chasis number</li>
                        </div>
                    </div>
                </div>
                <div className='col-lg-5' style={{display: 'flex'}}>
                    <img src={Image} alt='' width='100%' className='service-summary-image'></img>
                </div>
            </div>

            <img src={Image} alt='' width='100%' className='small-view-service-summary-image'></img>
        </div>
    )
}