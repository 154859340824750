import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Landing from "./Home/Landing";
import Services from "./Services/Services";
import About from "./About";
import ContactUs from "./ContactUs/ContactUs";

export default function Router() {
    return (
        <ScrollToTop>
            <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/about' element={<About />} />
                <Route path='/services' element={<Services />} />
                <Route path='/contact' element={<ContactUs />} />
            </Routes>
        </ScrollToTop>
    )
}